import React from 'react';

import Layout from './components/layout';
// ** import styles and fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '5628e61a-be40-4117-ba42-65da7ff78cc6',
    clientToken: 'pub72b7397abad2bd1a0a87d81f8c00e6f7',
    site: 'datadoghq.com',
    service:'partner-express-form',
    env:'staging:selectAndSwitch',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: '1.1.0', 
    sessionSampleRate:100,
    sessionReplaySampleRate: 1000,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow'
});
    
datadogRum.startSessionReplayRecording();
    
datadogRum.startSessionReplayRecording();
    
function PartnerExpressForm() {

datadogRum.startSessionReplayRecording();    
  
  return (
    <Layout />
  );
}

export default PartnerExpressForm;
